.validator-card,
.validator-row,
.validator-sort {
  cursor: pointer;
}

.validator-card:hover {
  background-color: beige !important;
}

@media (min-width: 992px) {
  .validator-table {
    display: table;
  }
  .validator-card {
    display: none;
  }
  .validator-cards {
    display: none;
  }
  .tradingview-market-full {
    display: block;
  }
  .tradingview-market-mobile {
    display: none;
  }
}

@media (max-width: 991px) {
  .validator-table {
    display: none;
  }
  .validator-card {
    display: block;
  }
  .validator-cards {
    display: block;
  }
  .tradingview-market-full {
    display: none;
  }
  .tradingview-market-mobile {
    display: block;
  }
}

@media (min-width: 768px) {
  .desktop-xp {
    display: block;
  }
  .mobile-xp {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .desktop-xp {
    display: none !important;
  }
  .mobile-xp {
    display: block;
  }
}

.validator-address {
  font-weight: 700;
  color: black;
}

.search-title {
  font-weight: 700;
}

#TICKER_TAPE_1 .tradingview-widget-copyright {
  display: none;
}

#SYMBOL_OVERVIEW_1 .tradingview-widget-copyright {
  display: none;
}

#ADVANCED_CHART_1 .tradingview-widget-copyright {
  display: none;
}
