.divider-spacing {
    padding-top: 3em;
}

.hero-description {
    font-size: medium;
    line-height: 1.2em;
}

.cta {
    will-change: transform;
    transform: translate3d(0px, 0px, 0px) scale3d(0.990734, 0.990734, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
}